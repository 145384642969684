import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ArticlePreview from '../components/article-preview'

class BlogIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    return (
      <Layout location={this.props.location}>
        <Seo title="News" />
        <Hero title="News" />
        <ArticlePreview posts={posts} />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(
      sort: { fields: [date], order: DESC }
      filter: { slug: { ne: "donotuse" } }
    ) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM Do, YYYY")
          tags
          image {
            title
            file {
              url
              contentType
            }
            gatsbyImage(
              layout: FULL_WIDTH
              placeholder: BLURRED
              width: 400
              height: 400
            )
          }
          link
          body {
            raw
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`
